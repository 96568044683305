/*eslint sort-keys: "error"*/
const mapping = {
  'sections.activity-carousel': 'SectionActivityCarousel',
  'sections.activity-sheet': 'SectionActivitySheet',
  'sections.article-details': 'SectionArticleDetails',
  'sections.article-listing-with-filter': 'SectionArticleListingWithFilter',
  'sections.breadcrumbs': 'SectionBreadcrumbs',
  'sections.city-carousel': 'SectionCityCarousel',
  'sections.city-sheet': 'SectionCitySheet',
  'sections.divider': 'SectionDivider',
  'sections.event-details': 'SectionEventDetails',
  'sections.event-listing-with-filter': 'SectionEventListingWithFilter',
  'sections.faqs': 'SectionFaqs',
  'sections.headline-with-text': 'SectionHeadlineWithText',
  'sections.headline-with-text-and-cards': 'SectionHeadlineWithTextAndCards',
  'sections.headline-with-text-and-horizontal-cards':
    'SectionHeadlineWithTextAndHorizontalCards',
  'sections.headline-with-text-and-icons': 'SectionHeadlineWithTextAndIcons',
  'sections.headline-with-text-and-image': 'SectionHeadlineWithTextAndImage',
  'sections.headline-with-text-and-image-v2':
    'SectionHeadlineWithTextAndImageV2',
  'sections.headline-with-text-and-numbers':
    'SectionHeadlineWithTextAndNumbers',
  'sections.headline-with-text-and-youtube-video':
    'SectionHeadlineWithTextAndYoutubeVideo',
  'sections.homepage-cards': 'SectionHomepageContentCards',
  'sections.homepage-hero': 'SectionHomepageHero',
  'sections.homepage-job-cards': 'SectionHomepageTopJobs',
  'sections.homepage-search-buttons': 'SectionHomepageSearchButtons',
  'sections.job-box-with-tabs': 'SectionHomepageJobBoxWithTabs',
  'sections.job-box-with-typesense': 'SectionJobBoxWithTypesense',
  'sections.job-box-without-tabs': 'SectionJobBoxWithoutTabs',
  'sections.job-details': 'SectionJobDetails',
  'sections.job-listing-with-filter': 'SectionJobListingWithFilter',
  'sections.job-listing-with-typesense': 'SectionJobListingWithTypesense',
  'sections.landing-page-hero': 'SectionLandingPageHero',
  'sections.link-sheet': 'SectionLinkSheet',
  'sections.mautic-forms': 'SectionMauticForms',
  'sections.medium-hero': 'SectionMediumHero',
  'sections.my-account-deletion': 'SectionMyAccountDeletion',
  'sections.my-applications': 'SectionMyApplications',
  'sections.my-byll-account': 'SectionMyByllAccount',
  'sections.my-dashboard': 'SectionMyDashboard',
  'sections.my-data': 'SectionMyData',
  'sections.my-documents': 'SectionMyDocuments',
  'sections.my-email-change': 'SectionMyEmailChange',
  'sections.my-favorites': 'SectionMyFavorites',
  'sections.my-images': 'SectionMyImages',
  'sections.my-invoices': 'SectionMyInvoices',
  'sections.my-job-alerts': 'SectionMyJobAlerts',
  'sections.my-password-change': 'SectionMyPasswordChange',
  'sections.my-ratings': 'SectionMyRatings',
  'sections.my-settings': 'SectionMySettings',
  'sections.my-videos': 'SectionMyVideos',
  'sections.organization-carousel': 'SectionOrganizationCarousel',
  'sections.organization-details': 'SectionOrganizationDetails',
  'sections.organization-listing-with-filter':
    'SectionOrganizationListingWithFilter',
  'sections.organization-top-list': 'SectionOrganizationTopList',
  'sections.pricing-tables': 'SectionPricingTables',
  'sections.registration-button': 'SectionRegistrationButton',
  'sections.revive-adserver-zone': 'SectionReviveAdserverZone',
  'sections.seo-content': 'SectionSeoContent',
  'sections.sign-in': 'SectionSignIn',
  'sections.sign-in-cta-with-icon': 'SectionSignInCtaWithIcon',
  'sections.small-hero': 'SectionSmallHero',
  'sections.small-hero-with-profile-header':
    'SectionSmallHeroWithProfileHeader',
  'sections.testimonial-cards': 'SectionTestimonialCards',
  'sections.testimonial-listing': 'SectionTestimonialListing',
  'sections.wiki-details': 'SectionWikiDetails',
  'sections.wiki-listing-with-filter': 'SectionWikiListingWithFilter',
};

export async function mapSectionComponents(sections, callable) {
  callable = callable || (() => {});

  for (const section of sections) {
    if (mapping[section['__component']]) {
      section.component = mapping[section['__component']];
    }

    await callable(section);
  }

  return sections;
}

export function replaceActivityDataInSections(sections, activity) {
  sections.forEach((section) => {
    if (section.base_data && section.base_data.title) {
      section.base_data.title = section.base_data.title.replace(
        /%activity_name/g,
        activity.name.toUpperCase()
      );
    }

    if (section.base_data && section.base_data.intro_text) {
      section.base_data.intro_text = section.base_data.intro_text
        .replace(/%activity_name/g, activity.name)
        .replace(/%activity_description/g, activity.description)
        .replace(/%activity_content/g, activity.content);
    }

    if (section.base_data && section.base_data.finish_text) {
      section.base_data.finish_text = section.base_data.finish_text
        .replace(/%activity_name/g, activity.name)
        .replace(/%activity_description/g, activity.description)
        .replace(/%activity_content/g, activity.content);
    }

    switch (section.component) {
      case 'SectionActivityCarousel':
        section.activity = activity;
        break;

      case 'SectionHomepageContentCards':
        if (section.registrationCard && section.registrationCard.title) {
          section.registrationCard.title = section.registrationCard.title
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (section.registrationCard && section.registrationCard.subline) {
          section.registrationCard.subline = section.registrationCard.subline
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (
          section.registrationCard &&
          section.registrationCard.button &&
          section.registrationCard.button.text
        ) {
          section.registrationCard.button.text =
            section.registrationCard.button.text
              .replace(/%activity_name/g, activity.name)
              .replace(/%activity_description/g, activity.description)
              .replace(/%activity_content/g, activity.content);
        }
        if (
          section.registrationCard &&
          section.registrationCard.button &&
          section.registrationCard.button.btn_route
        ) {
          section.registrationCard.button.btn_route = JSON.parse(
            JSON.stringify(section.registrationCard.button.btn_route)
              .replace(/%activity_name/g, activity.name)
              .replace(/%activity_slug/g, activity.slug)
          );
        }

        if (section.jobListingCard && section.jobListingCard.title) {
          section.jobListingCard.title = section.jobListingCard.title
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (section.jobListingCard && section.jobListingCard.subline) {
          section.jobListingCard.subline = section.jobListingCard.subline
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (
          section.jobListingCard &&
          section.jobListingCard.button &&
          section.jobListingCard.button.text
        ) {
          section.jobListingCard.button.text =
            section.jobListingCard.button.text
              .replace(/%activity_name/g, activity.name)
              .replace(/%activity_description/g, activity.description)
              .replace(/%activity_content/g, activity.content);
        }
        if (
          section.jobListingCard &&
          section.jobListingCard.button &&
          section.jobListingCard.button.btn_route
        ) {
          section.jobListingCard.button.btn_route = JSON.parse(
            JSON.stringify(section.jobListingCard.button.btn_route)
              .replace(/%activity_name/g, activity.name)
              .replace(/%activity_slug/g, activity.slug)
          );
        }

        if (section.profileCard && section.profileCard.title) {
          section.profileCard.title = section.profileCard.title
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (section.profileCard && section.profileCard.subline) {
          section.profileCard.subline = section.profileCard.subline
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (
          section.profileCard &&
          section.profileCard.button &&
          section.profileCard.button.text
        ) {
          section.profileCard.button.text = section.profileCard.button.text
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (
          section.profileCard &&
          section.profileCard.button &&
          section.profileCard.button.btn_route
        ) {
          section.profileCard.button.btn_route = JSON.parse(
            JSON.stringify(section.profileCard.button.btn_route)
              .replace(/%activity_name/g, activity.name)
              .replace(/%activity_slug/g, activity.slug)
          );
        }
        break;

      case 'SectionHomepageJobBoxWithTabs':
      case 'SectionJobBoxWithoutTabs':
        if (!section.filterParams) {
          section.filterParams = {};
        }
        section.filterParams.activities = activity.activity_keys;
        break;

      case 'SectionJobBoxWithTypesense':
      case 'SectionJobListingWithFilter':
      case 'SectionJobListingWithTypesense':
        if (section.title) {
          section.title = section.title
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (section.intro_text) {
          section.intro_text = section.intro_text
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }

        if (!section.filterParams) {
          section.filterParams = {};
        }
        section.filterParams.activities = activity.activity_keys;
        break;

      case 'SectionLandingPageHero':
      case 'SectionMediumHero':
      case 'SectionSmallHero':
        if (section.title) {
          section.title = section.title
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }

        if (section.subline) {
          section.subline = section.subline
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }

        if (activity.header_image) {
          section.image = activity.header_image;
        }
        break;

      case 'SectionSeoContent':
        section.activity = activity.slug;

        if (activity.seo_content_config) {
          section.seo_content_config = activity.seo_content_config;
        }
        break;

      case 'SectionSignIn':
        if (section.title) {
          section.title = section.title
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (section.subline) {
          section.subline = section.subline
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        if (section.content) {
          section.content = section.content
            .replace(/%activity_name/g, activity.name)
            .replace(/%activity_description/g, activity.description)
            .replace(/%activity_content/g, activity.content);
        }
        break;

      case 'SectionHeadlineWithTextAndCards':
        section.cards.forEach((card) => {
          if (card.title) {
            card.title = card.title
              .replace(/%activity_name/g, activity.name)
              .replace(/%activity_description/g, activity.description)
              .replace(/%activity_content/g, activity.content);
          }
          if (card.subline) {
            card.subline = card.subline
              .replace(/%activity_name/g, activity.name)
              .replace(/%activity_description/g, activity.description)
              .replace(/%activity_content/g, activity.content);
          }

          if (card.button && card.button.text) {
            card.button.text = card.button.text
              .replace(/%activity_name/g, activity.name)
              .replace(/%activity_description/g, activity.description)
              .replace(/%activity_content/g, activity.content);
          }

          if (card.button && card.button.btn_route) {
            card.button.btn_route = JSON.parse(
              JSON.stringify(card.button.btn_route)
                .replace(/%activity_name/g, activity.name)
                .replace(/%activity_slug/g, activity.slug)
            );
          }
        });
        break;
    }
  });

  return sections;
}

export function replaceCityDataInSections(sections, city) {
  sections.forEach((section) => {
    if (section.base_data && section.base_data.title) {
      section.base_data.title = section.base_data.title.replace(
        /%city_name/g,
        city.name.toUpperCase()
      );
    }

    if (section.base_data && section.base_data.intro_text) {
      section.base_data.intro_text = section.base_data.intro_text
        .replace(/%city_name/g, city.name)
        .replace(/%city_description/g, city.description)
        .replace(/%city_content/g, city.content);
    }

    if (section.base_data && section.base_data.finish_text) {
      section.base_data.finish_text = section.base_data.finish_text
        .replace(/%city_name/g, city.name)
        .replace(/%city_description/g, city.description)
        .replace(/%city_content/g, city.content);
    }

    switch (section.component) {
      case 'SectionActivityCarousel':
        section.city = city;
        break;

      case 'SectionHomepageContentCards':
        if (section.registrationCard && section.registrationCard.title) {
          section.registrationCard.title = section.registrationCard.title
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (section.registrationCard && section.registrationCard.subline) {
          section.registrationCard.subline = section.registrationCard.subline
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (
          section.registrationCard &&
          section.registrationCard.button &&
          section.registrationCard.button.text
        ) {
          section.registrationCard.button.text =
            section.registrationCard.button.text
              .replace(/%city_name/g, city.name)
              .replace(/%city_description/g, city.description)
              .replace(/%city_content/g, city.content);
        }
        if (
          section.registrationCard &&
          section.registrationCard.button &&
          section.registrationCard.button.btn_route
        ) {
          section.registrationCard.button.btn_route = JSON.parse(
            JSON.stringify(section.registrationCard.button.btn_route)
              .replace(/%city_name/g, city.name)
              .replace(/%city_slug/g, city.slug)
          );
        }

        if (section.jobListingCard && section.jobListingCard.title) {
          section.jobListingCard.title = section.jobListingCard.title
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (section.jobListingCard && section.jobListingCard.subline) {
          section.jobListingCard.subline = section.jobListingCard.subline
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (
          section.jobListingCard &&
          section.jobListingCard.button &&
          section.jobListingCard.button.text
        ) {
          section.jobListingCard.button.text =
            section.jobListingCard.button.text
              .replace(/%city_name/g, city.name)
              .replace(/%city_description/g, city.description)
              .replace(/%city_content/g, city.content);
        }
        if (
          section.jobListingCard &&
          section.jobListingCard.button &&
          section.jobListingCard.button.btn_route
        ) {
          section.jobListingCard.button.btn_route = JSON.parse(
            JSON.stringify(section.jobListingCard.button.btn_route)
              .replace(/%city_name/g, city.name)
              .replace(/%city_slug/g, city.slug)
          );
        }

        if (section.profileCard && section.profileCard.title) {
          section.profileCard.title = section.profileCard.title
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (section.profileCard && section.profileCard.subline) {
          section.profileCard.subline = section.profileCard.subline
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (
          section.profileCard &&
          section.profileCard.button &&
          section.profileCard.button.text
        ) {
          section.profileCard.button.text = section.profileCard.button.text
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (
          section.profileCard &&
          section.profileCard.button &&
          section.profileCard.button.btn_route
        ) {
          section.profileCard.button.btn_route = JSON.parse(
            JSON.stringify(section.profileCard.button.btn_route)
              .replace(/%city_name/g, city.name)
              .replace(/%city_slug/g, city.slug)
          );
        }
        break;

      case 'SectionHomepageJobBoxWithTabs':
      case 'SectionJobBoxWithoutTabs':
        if (!section.filterParams) {
          section.filterParams = {};
        }
        section.filterParams.latitude = city.latitude;
        section.filterParams.longitude = city.longitude;
        section.filterParams.distance = city.distance;
        break;

      case 'SectionJobBoxWithTypesense':
      case 'SectionJobListingWithFilter':
      case 'SectionJobListingWithTypesense':
        if (section.title) {
          section.title = section.title
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (section.intro_text) {
          section.intro_text = section.intro_text
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }

        if (!section.filterParams) {
          section.filterParams = {};
        }
        section.filterParams.latitude = city.latitude;
        section.filterParams.longitude = city.longitude;
        section.filterParams.distance = city.distance;
        break;

      case 'SectionLandingPageHero':
      case 'SectionMediumHero':
      case 'SectionSmallHero':
        if (section.title) {
          section.title = section.title
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }

        if (section.subline) {
          section.subline = section.subline
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }

        if (city.header_image) {
          section.image = city.header_image;
        }
        break;

      case 'SectionSeoContent':
        section.city = city.slug;

        if (city.seo_content_config) {
          section.seo_content_config = city.seo_content_config;
        }
        break;

      case 'SectionSignIn':
        if (section.title) {
          section.title = section.title
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (section.subline) {
          section.subline = section.subline
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        if (section.content) {
          section.content = section.content
            .replace(/%city_name/g, city.name)
            .replace(/%city_description/g, city.description)
            .replace(/%city_content/g, city.content);
        }
        break;

      case 'SectionHeadlineWithTextAndCards':
        section.cards.forEach((card) => {
          if (card.title) {
            card.title = card.title
              .replace(/%city_name/g, city.name)
              .replace(/%city_description/g, city.description)
              .replace(/%city_content/g, city.content);
          }
          if (card.subline) {
            card.subline = card.subline
              .replace(/%city_name/g, city.name)
              .replace(/%city_description/g, city.description)
              .replace(/%city_content/g, city.content);
          }

          if (card.button && card.button.text) {
            card.button.text = card.button.text
              .replace(/%city_name/g, city.name)
              .replace(/%city_description/g, city.description)
              .replace(/%city_content/g, city.content);
          }

          if (card.button && card.button.btn_route) {
            card.button.btn_route = JSON.parse(
              JSON.stringify(card.button.btn_route)
                .replace(/%city_name/g, city.name)
                .replace(/%city_slug/g, city.slug)
            );
          }
        });
        break;
    }
  });

  return sections;
}
