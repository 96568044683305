import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  async getOrganizationProfileStarRatings(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(
        process.env.NUXT_ENV_API_ORGANIZATION_PROFILE_STAR_RATINGS,
        {
          routeParameters: {id},
        }
      )
    );
    return response.data.promotionbasis['hydra:totalItems'];
  },
  async giveStar(ctx, data) {
    const response = await getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_PROFILE_GIVE_STAR),
      data
    );
    return response.data.promotionbasis;
  },
  async removeStar(ctx, id) {
    const response = await getInstance().delete(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_PROFILE_REMOVE_STAR, {
        routeParameters: id,
      })
    );
    return {
      data: response.data.promotionbasis,
    };
  },
};
