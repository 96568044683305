import {getInstance} from '@/helper/Axios';
import {getInstanceWithoutAuthorizationHeader} from '@/helper/Axios';
import {prepareUrl, prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  async contactAboutProject(ctx, {data}) {
    return getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_CONTACT_ABOUT_PROJECT),
      data
    );
  },

  async generalContact(ctx, {data}) {
    return getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_GENERAL_CONTACT),
      data
    );
  },

  async getOrganizationProfiles(ctx, getParameters = {}) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_PROFILES_ENDPOINT, {
        getParameters,
      })
    );
    return {
      data: response.data.promotionbasis['hydra:member'],
      totalItems: response.data.promotionbasis['hydra:totalItems'],
    };
  },

  async organizationContactedFromUser(ctx, {id, data}) {
    return getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_CONTACTED_FROM_USER, {
        routeParameters: {id},
      }),
      data
    );
  },

  async organizationContactedFromVisitor(ctx, {id, data}) {
    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_ORGANIZATION_CONTACTED_FROM_VISITOR,
        {
          routeParameters: {id},
        }
      ),
      data
    );
  },

  async getTopOrganizationProfiles(ctx, getParameters = '') {
    const response = await getInstance().get(
      prepareApiUrl(
        process.env.NUXT_ENV_API_ORGANIZATION_PROFILES_ENDPOINT +
          '&' +
          getParameters
      )
    );
    return {
      data: response.data.promotionbasis['hydra:member'],
      totalItems: response.data.promotionbasis['hydra:totalItems'],
    };
  },

  async getOrganizationProfile(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_PROFILE_ENDPOINT, {
        routeParameters: id,
      })
    );
    return response.data.promotionbasis;
  },

  async getOrganizationProfileBySlug(ctx, slug) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_PROFILES_ENDPOINT, {
        getParameters: {slug},
      })
    );

    return response.data.promotionbasis['hydra:member'][0];
  },

  async getOrganizationsFromTypesenseMultisearch(
    ctx,
    {collection_name, getParameters = new URLSearchParams(), searches}
  ) {
    const headers = {};
    if (process.env.NUXT_ENV_TYPESENSE_API_KEY)
      headers['X-TYPESENSE-API-KEY'] = process.env.NUXT_ENV_TYPESENSE_API_KEY;
    const response = await getInstanceWithoutAuthorizationHeader().post(
      prepareUrl(process.env.NUXT_ENV_TYPESENSE_MULTISEARCH, {
        routeParameters: {collection_name},
        getParameters,
      }),
      {searches},
      {headers}
    );
    return response.data;
  },

  async getOrganizationCards(ctx, getParameters = new URLSearchParams()) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATIONS_ENDPOINT, {
        getParameters,
      })
    );
    return {
      data: response.data.promotionbasis['hydra:member'],
      totalItems: response.data.promotionbasis['hydra:totalItems'],
    };
  },

  async getOrganizationCard(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_ENDPOINT, {
        routeParameters: {id},
      })
    );
    return response.data.promotionbasis;
  },

  postRegistration(ctx, data) {
    const payload = {};

    // Add query-parameter/utm-parameter to payload
    if (this.$router.currentRoute.query) {
      payload.query = this.$router.currentRoute.query;
    }

    data.payload = payload;

    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_ORGANIZATION_REGISTRATION_ENDPOINT
      ),
      data
    );
  },

  /*async sendMauticForm(ctx, {id, data}) {
    return getInstance().post(
      process.env.NUXT_ENV_MAUTIC_FORMS + '?formId=' + id,
      data
    );
  },*/
};
