import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  patchProfile(ctx, {id, data}) {
    return getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_MASTER_DATA_ENDPOINT, {
        routeParameters: {id},
      }),
      data,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  getSedcard(ctx, {id, getParameters = {}}) {
    return getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_SEDCARD_ENDPOINT, {
        routeParameters: {id},
        getParameters: getParameters,
      }),
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  postRegistration(ctx, data) {
    const payload = {};

    // Add query-parameter/utm-parameter to payload
    if (this.$router.currentRoute.query) {
      payload.query = this.$router.currentRoute.query;
    }

    data.payload = payload;

    return getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_REGISTRATION_ENDPOINT),
      data
    );
  },

  emailCheck(ctx, data) {
    return getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_EMAIL_CHECK_ENDPOINT),
      data
    );
  },

  registerWithoutPassword(ctx, data) {
    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_PROFILE_REGISTER_WITHOUT_PASSWORD_ENDPOINT
      ),
      data
    );
  },

  postRegistrationAndApply(ctx, {data, getParameters = {}}) {
    const payload = {};

    // Add query-parameter/utm-parameter to payload
    if (this.$router.currentRoute.query) {
      payload.query = this.$router.currentRoute.query;
    }

    data.payload = payload;

    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_PROFILE_REGISTER_AND_APPLY_ENDPOINT,
        {getParameters}
      ),
      data
    );
  },

  async getPBasisSearchStatus(
    ctx,
    {candidate_profile_id, organization_profile_id}
  ) {
    const searchStatusResponse = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_PBASIS_SEARCH_STATUS, {
        routeParameters: {candidate_profile_id, organization_profile_id},
      })
    );
    return {
      data: searchStatusResponse.data.promotionbasis,
    };
  },
};
