import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  async postProfileDocumentBusinessLicense(ctx, data) {
    const response = await getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_PROFILE_DOCUMENTS_BUSINESS_LICENSE
      ),
      data
    );
    return response.data.promotionbasis;
  },
  async postProfileDocumentEnrollmentCertificate(ctx, data) {
    const response = await getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_PROFILE_DOCUMENTS_ENROLLMENT_CERTIFICATE
      ),
      data
    );
    return response.data.promotionbasis;
  },
  async postProfileDocumentMisc(ctx, data) {
    const response = await getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_DOCUMENTS_MISC),
      data
    );
    return response.data.promotionbasis;
  },

  async patchProfileDocument(ctx, {id, data}) {
    const response = await getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_DOCUMENTS, {
        routeParameters: {id},
      }),
      data,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
    return response.data.promotionbasis;
  },

  async deleteProfileDocument(ctx, id) {
    const response = await getInstance().delete(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_DOCUMENTS, {
        routeParameters: {id},
      })
    );
    return response.data.promotionbasis;
  },
};
