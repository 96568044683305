import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.ByllAccountCreated, () => {
    $matomo.trackEvent('UserInteractions', Events.ByllAccountCreated, '', 50);
  });
  EventBus.$on(Events.ByllUsed, () => {
    $matomo.trackEvent('UserInteractions', Events.ByllUsed, '', 5);
  });
  EventBus.$on(Events.EmailVerified, () => {
    $matomo.trackEvent('UserInteractions', Events.EmailVerified, '', 50);
  });
  EventBus.$on(Events.OrganizationContacted, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.OrganizationContacted,
      params.organizationProfileId,
      20
    );
  });
  EventBus.$on(Events.OrganizationProjectRequest, () => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.OrganizationProjectRequest,
      '',
      20
    );
  });
  EventBus.$on(Events.GeneralContactRequest, () => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.GeneralContactRequest,
      '',
      20
    );
  });
  EventBus.$on(Events.ProfileCompleteButNotVerifiedDialogOpened, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.ProfileCompleteButNotVerifiedDialogOpened,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.JobFavored, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.JobFavored,
      params.jobPostingId,
      10
    );
  });
  EventBus.$on(Events.JobsShared, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.JobsShared,
      params.jobPostingId,
      10
    );
  });
  EventBus.$on(Events.ArticlesShared, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.ArticlesShared,
      params.articleId,
      10
    );
  });
  EventBus.$on(Events.EventsShared, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.EventsShared,
      params.eventId,
      10
    );
  });
  EventBus.$on(Events.WikisShared, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.WikisShared,
      params.wikiId,
      10
    );
  });
  EventBus.$on(Events.NewsletterSettingChanged, (params) => {
    if (params.subscribed) {
      $matomo.trackEvent(
        'UserInteractions',
        Events.NewsletterSettingChanged,
        params.subscribed,
        5
      );
    }
  });
  EventBus.$on(Events.ProfileDataChanged, () => {
    $matomo.trackEvent('UserInteractions', Events.ProfileDataChanged, '', 25);
  });
  EventBus.$on(Events.ProfileDocumentChanged, () => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.ProfileDocumentChanged,
      '',
      25
    );
  });
  EventBus.$on(Events.ProfileImageChanged, () => {
    $matomo.trackEvent('UserInteractions', Events.ProfileImageChanged, '', 25);
  });
  EventBus.$on(Events.ProfileVideoChanged, () => {
    $matomo.trackEvent('UserInteractions', Events.ProfileVideoChanged, '', 25);
  });
  EventBus.$on(Events.ProfileSearchableChanged, (params) => {
    if (params.searchable) {
      $matomo.trackEvent(
        'UserInteractions',
        Events.ProfileSearchableChanged,
        params.searchable,
        5
      );
    }
  });
  EventBus.$on(Events.ProfileIncompleteDialogOpened, () => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.ProfileIncompleteDialogOpened,
      '',
      1
    );
  });
  EventBus.$on(Events.UserSettingsChanged, () => {
    $matomo.trackEvent('UserInteractions', Events.UserSettingsChanged, '', 25);
  });
  EventBus.$on(Events.UserDeleted, () => {
    $matomo.trackEvent('UserInteractions', Events.UserDeleted, '', 1);
  });
  EventBus.$on(Events.JobAlertSubscribed, () => {
    $matomo.trackEvent('UserInteractions', Events.JobAlertSubscribed, '', 25);
  });
  EventBus.$on(Events.JobAlertUnsubscribed, () => {
    $matomo.trackEvent('UserInteractions', Events.JobAlertUnsubscribed, '', 25);
  });
  EventBus.$on(Events.MauticFormSent, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.MauticFormSent,
      params.formName,
      20
    );
  });
  EventBus.$on(Events.AccountVerify, (params) => {
    $matomo.trackEvent(
      'UserInteractions',
      Events.AccountVerify,
      params.type,
      5
    );
  });
}
