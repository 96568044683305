
  import ScreenSize from '@/mixins/ScreenSize';
  import {mapSectionComponents} from '@/helper/SectionHelper';
  import LazyHydrate from 'vue-lazy-hydration';

  export default {
    name: 'Error',
    components: {
      ErrorWithCode: () => import('@/components/ErrorWithCode'),
      LazyHydrate,
    },
    mixins: [ScreenSize],
    layout(context) {
      let layout = 'default';
      if (context.route.fullPath.includes('/recruiting')) {
        layout = 'organization';
      }
      return layout;
    },
    props: {
      error: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        metaData: {},
        seoContentConfig: {},
        sections: [],
      };
    },
    head() {
      const title =
        this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
      return {
        title,
      };
    },
    async created() {
      let errorPage = 'error';
      if (this.$nuxt.$route.fullPath.includes('/recruiting')) {
        errorPage = 'error-organization';
      }
      // Query page from Strapi; strapi-change for findOne is required!
      const page = await this.$cachedStrapi
        .findOne('pages', errorPage)
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });

      const sections = await mapSectionComponents(
        page.sections,
        async function (section) {
          switch (section.component) {
            case 'SectionSeoContent':
              if (
                section.seo_content_config &&
                section.seo_content_config.seo_contents &&
                section.seo_content_config.seo_contents[0]
              ) {
                section.seoContent = await this.$cachedStrapi.findOne(
                  'seo-contents',
                  section.seo_content_config.seo_contents[0].id
                );
              }
              break;
          }
        }
      );

      const metaData = page && page.meta ? page.meta : {};
      const seoContentConfig =
        page && page.seo_content_config ? page.seo_content_config : {};

      this.metaData = metaData;
      this.seoContentConfig = seoContentConfig;
      this.sections = sections;
    },
  };
