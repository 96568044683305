export default function ({app, query}) {
  let organization_parameter = {};
  if (
    query.organization_profile_id &&
    query.mtm_source &&
    query.mtm_medium &&
    query.mtm_group
  ) {
    organization_parameter = {
      id: query.organization_profile_id,
      source: query.mtm_source,
      medium: query.mtm_medium,
      group: query.mtm_group,
    };
  }

  const isEmpty = !Object.values(organization_parameter).some(
    (x) => x !== null && x !== ''
  );

  if (!isEmpty) {
    app.$cookies.set('organization_parameter', organization_parameter);
  }
}
