/*eslint sort-keys: "error"*/
export const i18nRoutes = {
  _slug: {
    de: '/:slug',
  },
  'articles/_category/_article/index': {
    de: '/tipps-faq/:category/:article',
  },
  'articles/_category/index': {
    de: '/tipps-faq/:category',
  },
  'articles/index': {
    de: '/tipps-faq',
  },
  'events/_category/_event/index': {
    de: '/messen-events/:category/:event',
  },
  'events/_category/index': {
    de: '/messen-events/:category',
  },
  'events/index': {
    de: '/messen-events',
  },
  'job-application/_slug': {
    de: '/jobs-bewerbung/:slug',
  },
  'job/_id': {
    de: '/jobs/:id',
  },
  'job/_slug': {
    de: '/jobs/:slug',
  },
  'job/jobNotFound': {
    de: '/jobs/job-nicht-gefunden',
  },
  'jobs-query': {
    de: '/jobs/suche/',
  },
  'jobs-search-activity': {
    de: '/jobs/suche-als/:activity*',
  },
  'jobs-search-what': {
    de: '/jobs/suche-nach/:what',
  },
  'jobs/index': {
    de: '/jobs',
  },
  'landingpages/activities/_activity/index': {
    de: '/jobs-taetigkeit/:activity',
  },
  'landingpages/activities/index': {
    de: '/jobs-taetigkeit',
  },
  'landingpages/cities/_city/_activity/index': {
    de: '/jobs-stadt/:city/als/:activity',
  },
  'landingpages/cities/_city/index': {
    de: '/jobs-stadt/:city',
  },
  'landingpages/cities/index': {
    de: '/jobs-stadt',
  },
  'landingpages/eventjobs/_city/index': {
    de: '/eventjobs/:city',
  },
  'landingpages/eventjobs/index': {
    de: '/eventjobs',
  },
  'landingpages/ferienjobs/_city/index': {
    de: '/ferienjobs/:city',
  },
  'landingpages/ferienjobs/index': {
    de: '/ferienjobs',
  },
  'landingpages/gastrojobs/_city/index': {
    de: '/gastrojobs/:city',
  },
  'landingpages/gastrojobs/index': {
    de: '/gastrojobs',
  },
  'landingpages/messejobs/_city/index': {
    de: '/messejobs/:city',
  },
  'landingpages/messejobs/index': {
    de: '/messejobs',
  },
  'landingpages/nebenjobs/_city/index': {
    de: '/nebenjobs/:city',
  },
  'landingpages/nebenjobs/index': {
    de: '/nebenjobs',
  },
  'landingpages/promotionjobs/_city/index': {
    de: '/promotionjobs/:city',
  },
  'landingpages/promotionjobs/index': {
    de: '/promotionjobs',
  },
  'landingpages/rentnerjobs/_city/index': {
    de: '/rentnerjobs/:city',
  },
  'landingpages/rentnerjobs/index': {
    de: '/rentnerjobs',
  },
  'landingpages/schuelerjobs/_city/index': {
    de: '/schuelerjobs/:city',
  },
  'landingpages/schuelerjobs/index': {
    de: '/schuelerjobs',
  },
  'landingpages/studentenjobs/_city/index': {
    de: '/studentenjobs/:city',
  },
  'landingpages/studentenjobs/index': {
    de: '/studentenjobs',
  },
  myAccountDeletion: {
    de: '/meinen-account-loeschen',
  },
  myApplications: {
    de: '/meine-bewerbungen',
  },
  myByllAccount: {
    de: '/mein-byll-account',
  },
  myDashboard: {
    de: '/mein-dashboard',
  },
  myData: {
    de: '/meine-daten',
  },
  myDocuments: {
    de: '/meine-dokumente',
  },
  myEmailChange: {
    de: '/meine-email-aendern',
  },
  myFavorites: {
    de: '/meine-favoriten',
  },
  myImages: {
    de: '/meine-bilder',
  },
  myJobAlerts: {
    de: '/meine-job-alerts',
  },
  myPasswordChange: {
    de: '/mein-passwort-aendern',
  },
  myProfile: {
    de: '/meine-sedcard',
  },
  myRatings: {
    de: '/meine-bewertungen',
  },
  mySettings: {
    de: '/meine-einstellungen',
  },
  myVideos: {
    de: '/meine-videos',
  },
  'organization/_slug': {
    de: '/recruiting/:slug',
  },
  'organization/articles/_category/_article/index': {
    de: '/recruiting/hr-wissen/:category/:article',
  },
  'organization/articles/_category/index': {
    de: '/recruiting/hr-wissen/:category',
  },
  'organization/articles/index': {
    de: '/recruiting/hr-wissen',
  },
  'organization/index': {
    de: '/recruiting/',
  },
  'organization/wikis/_slug': {
    de: '/recruiting/wiki/:slug',
  },
  'organization/wikis/index': {
    de: '/recruiting/wiki',
  },
  'organizations/_slug': {
    de: '/jobanbieter/:slug',
  },
  'organizations/index': {
    de: '/jobanbieter',
  },
  'wikis/_slug': {
    de: '/wiki/:slug',
  },
  'wikis/index': {
    de: '/wiki',
  },
};
// hard-coded used in pages to get data from strapi
export const fixedRouteSlugs = [
  'articles',
  'articles-category',
  'articles-category-article',
  'events',
  'events-category',
  'events-category-event',
  'error',
  'error-organization',
  'home',
  'home-jobanbieter',
  'jobdetails',
  'jobs',
  'jobdetails-inactive',
  'jobdetails-not-found',
  'landingpages-activities',
  'landingpages-activities-activity',
  'landingpages-cities',
  'landingpages-cities-city',
  'landingpages-cities-city-activity',
  'landingpages-eventjobs',
  'landingpages-eventjobs-city',
  'landingpages-ferienjobs',
  'landingpages-ferienjobs-city',
  'landingpages-gastrojobs',
  'landingpages-gastrojobs-city',
  'landingpages-messejobs',
  'landingpages-messejobs-city',
  'landingpages-nebenjobs',
  'landingpages-nebenjobs-city',
  'landingpages-promotionjobs',
  'landingpages-promotionjobs-city',
  'landingpages-rentnerjobs',
  'landingpages-rentnerjobs-city',
  'landingpages-schuelerjobs',
  'landingpages-schuelerjobs-city',
  'landingpages-studentenjobs',
  'landingpages-studentenjobs-city',
  'my-account-deletion',
  'my-applications',
  'my-byll-account',
  'my-dashboard',
  'my-data',
  'my-documents',
  'my-email-change',
  'my-favorites',
  'my-images',
  'my-invoices',
  'my-job-alerts',
  'my-password-change',
  'my-ratings',
  'my-settings',
  'my-videos',
  'organization-articles',
  'organization-articles-category',
  'organization-articles-category-article',
  'organization-profile',
  'organization-wikis',
  'organization-wikis-slug',
  'organizations',
  'organization-profile',
  'wikis',
  'wikis-slug',
];
// hard-coded used in routes to handle breadcrumbs for job-listing
export const jobListingRoutes = [
  'jobs',
  'landingpages-activities',
  'landingpages-activities-activity',
  'landingpages-cities',
  'landingpages-cities-city',
  'landingpages-cities-city-activity',
  'landingpages-eventjobs',
  'landingpages-eventjobs-city',
  'landingpages-ferienjobs',
  'landingpages-ferienjobs-city',
  'landingpages-gastrojobs',
  'landingpages-gastrojobs-city',
  'landingpages-messejobs',
  'landingpages-messejobs-city',
  'landingpages-nebenjobs',
  'landingpages-nebenjobs-city',
  'landingpages-promotionjobs',
  'landingpages-promotionjobs-city',
  'landingpages-rentnerjobs',
  'landingpages-rentnerjobs-city',
  'landingpages-schuelerjobs',
  'landingpages-schuelerjobs-city',
  'landingpages-studentenjobs',
  'landingpages-studentenjobs-city',
];

// hard-coded used in routes to handle breadcrumbs for organization-listing
export const organizationListingRoutes = ['organizations'];

// hard-coded used in routes to handle breadcrumbs for article-listing
export const articleListingRoutes = ['articles', 'articles-category'];

// hard-coded used in routes to handle breadcrumbs for event-listing
export const eventListingRoutes = ['events', 'events-category'];
