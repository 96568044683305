
  import ScreenSize from '@/mixins/ScreenSize';
  import Events from '@/events/Events';
  import EventBus from '@/events/EventBus';
  export default {
    name: 'CandidateLayout',
    components: {
      DefaultAppBar: () => import('@/layouts/default/AppBar'),
      DefaultFooter: () => import('@/layouts/default/Footer'),
      Notifications: () => import('@/components/Notifications'),
      CookieConsentBanner: () => import('@/components/CookieConsentBanner'),
      RegistrationOrLoginDialog: () =>
        import('@/components/candidate/RegistrationOrLoginDialog'),
      RegistrationAndApplicationDialog: () =>
        import('@/components/candidate/RegistrationAndApplicationDialog'),
      ApplyOnJobPostingDialog: () =>
        import('@/components/candidate/ApplyOnJobPostingDialog'),
      GeneralContactDialog: () => import('@/components/GeneralContactDialog'),
    },
    mixins: [ScreenSize],
    data() {
      return {
        savedApplyJob: null,
      };
    },
    head() {
      const meta = [
        {
          hid: 'og:url',
          property: 'og:url',
          content: process.env.NUXT_ENV_BASE_URL + this.$route.fullPath,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'Website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Promotionjobs | Messejobs | Eventjobs | Promotionbasis',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content:
            'Promotionbasis ist Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content:
            'Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content:
            'Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: process.env.NUXT_ENV_BASE_URL + '/PB_Logo.svg',
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: process.env.NUXT_ENV_BASE_URL + '/PB_Logo.svg',
        },
      ];

      if (process.env.NUXT_ENV_BASE_URL !== 'https://www.promotionbasis.de') {
        meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex',
        });
        meta.push({
          hid: 'googlebot',
          name: 'googlebot',
          content: 'noindex',
        });
      }

      return {
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        script: [
          {
            src: '//ads1.promotionbasis.de/www/delivery/asyncjs.php',
            body: true,
            async: true,
            defer: true,
          },
        ],
        link: [
          {
            rel: 'canonical',
            hid: 'canonical',
            href: process.env.NUXT_ENV_BASE_URL + this.localePath({}),
          },
        ],
        meta: meta,
      };
    },
    computed: {
      login() {
        return this.$route.query.login;
      },
      registration() {
        return this.$route.query.registration;
      },
      success() {
        return this.$route.query.success;
      },
      loginApply() {
        return this.$route.query.loginApply;
      },
      successRegister() {
        return this.$route.query.successRegister;
      },
      successApplication() {
        const sucessApplication = this.$route.query.successApplication;
        if (sucessApplication) {
          this.$emitWithClientData(Events.ThankYouPageOpened, {
            type: 'RegistrationAndApplication',
          });
        }
        return sucessApplication;
      },
      generalContact() {
        return this.$route.query.generalContact;
      },
      topic() {
        return this.$route.query.topic;
      },
    },
    watch: {
      '$route.query': async function () {
        this.savedApplyJob = null;
        const savedApplyJob = await this.$localforage.getItem('SavedApplyJob');
        if (savedApplyJob !== null && typeof savedApplyJob !== 'undefined') {
          // The item exists in localforage, and we can use it
          this.savedApplyJob = savedApplyJob;
        }
        if (this.$route.query.logout) {
          this.$auth.logout().then(() => {
            EventBus.$emit('logout');
            this.$notifications.showNotification({
              message: this.$t('action.user_logout'),
              color: 'success',
              timeout: 5000,
            });
            this.removeUrlParams();
          });
        }
      },
    },
    errorCaptured(err, vm, info) {
      if (info === 'render') {
        this.$router.push('/error');
      }
    },
    methods: {
      removeUrlParams() {
        const {path, params} = this.$route;
        const query = {};
        const newUrl = this.$router.resolve({path, query, params});
        this.$router.push(newUrl.href);
      },
    },
  };
