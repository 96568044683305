import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6e6ebcd0 = () => interopDefault(import('../pages/artikel-faq/index.vue' /* webpackChunkName: "pages/artikel-faq/index" */))
const _4489be54 = () => interopDefault(import('../pages/artikel-faq-detail-page/index.vue' /* webpackChunkName: "pages/artikel-faq-detail-page/index" */))
const _0c2f2a41 = () => interopDefault(import('../pages/landingpages/eventjobs/index.vue' /* webpackChunkName: "pages/landingpages/eventjobs/index" */))
const _340e9a6d = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _e49034cc = () => interopDefault(import('../pages/landingpages/ferienjobs/index.vue' /* webpackChunkName: "pages/landingpages/ferienjobs/index" */))
const _48d24fb3 = () => interopDefault(import('../pages/landingpages/gastrojobs/index.vue' /* webpackChunkName: "pages/landingpages/gastrojobs/index" */))
const _118eeac4 = () => interopDefault(import('../pages/job-detail-page/index.vue' /* webpackChunkName: "pages/job-detail-page/index" */))
const _18d27b83 = () => interopDefault(import('../pages/organizations/index.vue' /* webpackChunkName: "pages/organizations/index" */))
const _8b76568c = () => interopDefault(import('../pages/joblisting/index.vue' /* webpackChunkName: "pages/joblisting/index" */))
const _ab80a7ae = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))

const _6a114ca4 = () => interopDefault(import('../pages/landingpages/cities/index.vue' /* webpackChunkName: "pages/landingpages/cities/index" */))
const _fd1def5c = () => interopDefault(import('../pages/landingpages/activities/index.vue' /* webpackChunkName: "pages/landingpages/activities/index" */))
const _7384b72a = () => interopDefault(import('../pages/myByllAccount.vue' /* webpackChunkName: "pages/myByllAccount" */))
const _410997a8 = () => interopDefault(import('../pages/myDashboard.vue' /* webpackChunkName: "pages/myDashboard" */))
const _29c06f72 = () => interopDefault(import('../pages/myPasswordChange.vue' /* webpackChunkName: "pages/myPasswordChange" */))
const _0ae6ef82 = () => interopDefault(import('../pages/myApplications.vue' /* webpackChunkName: "pages/myApplications" */))
const _e39944ec = () => interopDefault(import('../pages/myRatings.vue' /* webpackChunkName: "pages/myRatings" */))
const _01568614 = () => interopDefault(import('../pages/myImages.vue' /* webpackChunkName: "pages/myImages" */))
const _7aa47b46 = () => interopDefault(import('../pages/myData.vue' /* webpackChunkName: "pages/myData" */))
const _6b796468 = () => interopDefault(import('../pages/myDocuments.vue' /* webpackChunkName: "pages/myDocuments" */))
const _4c84c13f = () => interopDefault(import('../pages/mySettings.vue' /* webpackChunkName: "pages/mySettings" */))
const _75a80f00 = () => interopDefault(import('../pages/myEmailChange.vue' /* webpackChunkName: "pages/myEmailChange" */))
const _377a7deb = () => interopDefault(import('../pages/myFavorites.vue' /* webpackChunkName: "pages/myFavorites" */))
const _6cda45c8 = () => interopDefault(import('../pages/myJobAlerts.vue' /* webpackChunkName: "pages/myJobAlerts" */))
const _7f94933d = () => interopDefault(import('../pages/myProfile.vue' /* webpackChunkName: "pages/myProfile" */))
const _1231e218 = () => interopDefault(import('../pages/myVideos.vue' /* webpackChunkName: "pages/myVideos" */))
const _e90dcca2 = () => interopDefault(import('../pages/myAccountDeletion.vue' /* webpackChunkName: "pages/myAccountDeletion" */))
const _0dcb1858 = () => interopDefault(import('../pages/landingpages/messejobs/index.vue' /* webpackChunkName: "pages/landingpages/messejobs/index" */))
const _9de2a9a8 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _14e5e2fc = () => interopDefault(import('../pages/myInvoices.vue' /* webpackChunkName: "pages/myInvoices" */))
const _3e39b19b = () => interopDefault(import('../pages/landingpages/nebenjobs/index.vue' /* webpackChunkName: "pages/landingpages/nebenjobs/index" */))
const _5c8afe77 = () => interopDefault(import('../pages/oauth.vue' /* webpackChunkName: "pages/oauth" */))
const _4f10a801 = () => interopDefault(import('../pages/oauthError.vue' /* webpackChunkName: "pages/oauthError" */))
const _0a85a154 = () => interopDefault(import('../pages/organizationProfile.vue' /* webpackChunkName: "pages/organizationProfile" */))
const _749534ec = () => interopDefault(import('../pages/landingpages/promotionjobs/index.vue' /* webpackChunkName: "pages/landingpages/promotionjobs/index" */))
const _f6ed5674 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _2113182e = () => interopDefault(import('../pages/landingpages/rentnerjobs/index.vue' /* webpackChunkName: "pages/landingpages/rentnerjobs/index" */))
const _a18bfca8 = () => interopDefault(import('../pages/landingpages/schuelerjobs/index.vue' /* webpackChunkName: "pages/landingpages/schuelerjobs/index" */))
const _487cc16a = () => interopDefault(import('../pages/landingpages/studentenjobs/index.vue' /* webpackChunkName: "pages/landingpages/studentenjobs/index" */))
const _5e84d150 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _048380e6 = () => interopDefault(import('../pages/wikis/index.vue' /* webpackChunkName: "pages/wikis/index" */))
const _0f3e2c5a = () => interopDefault(import('../pages/job/jobNotFound.vue' /* webpackChunkName: "pages/job/jobNotFound" */))
const _066a863c = () => interopDefault(import('../pages/organization/articles/index.vue' /* webpackChunkName: "pages/organization/articles/index" */))
const _00b0590c = () => interopDefault(import('../pages/organization/wikis/index.vue' /* webpackChunkName: "pages/organization/wikis/index" */))
const _3d4c8a8a = () => interopDefault(import('../pages/organization/articles/_category/index.vue' /* webpackChunkName: "pages/organization/articles/_category/index" */))
const _041d9b9c = () => interopDefault(import('../pages/organization/wikis/_slug.vue' /* webpackChunkName: "pages/organization/wikis/_slug" */))
const _3705c41c = () => interopDefault(import('../pages/organization/articles/_category/_article/index.vue' /* webpackChunkName: "pages/organization/articles/_category/_article/index" */))
const _a2a82108 = () => interopDefault(import('../pages/landingpages/eventjobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/eventjobs/_city/index" */))
const _7b870415 = () => interopDefault(import('../pages/landingpages/ferienjobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/ferienjobs/_city/index" */))
const _7a25b76e = () => interopDefault(import('../pages/landingpages/gastrojobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/gastrojobs/_city/index" */))
const _171bda3b = () => interopDefault(import('../pages/organizations/_slug.vue' /* webpackChunkName: "pages/organizations/_slug" */))
const _7243f44a = () => interopDefault(import('../pages/job-application/_slug.vue' /* webpackChunkName: "pages/job-application/_slug" */))
const _025c1929 = () => interopDefault(import('../pages/landingpages/cities/_city/index.vue' /* webpackChunkName: "pages/landingpages/cities/_city/index" */))
const _4b2d7df1 = () => interopDefault(import('../pages/landingpages/activities/_activity/index.vue' /* webpackChunkName: "pages/landingpages/activities/_activity/index" */))
const _bf6c53d0 = () => interopDefault(import('../pages/job/_slug.vue' /* webpackChunkName: "pages/job/_slug" */))
const _06707ecf = () => interopDefault(import('../pages/landingpages/messejobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/messejobs/_city/index" */))
const _ab09790c = () => interopDefault(import('../pages/events/_category/index.vue' /* webpackChunkName: "pages/events/_category/index" */))
const _9933e954 = () => interopDefault(import('../pages/landingpages/nebenjobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/nebenjobs/_city/index" */))
const _5dfe5ff6 = () => interopDefault(import('../pages/landingpages/promotionjobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/promotionjobs/_city/index" */))
const _fa5a9904 = () => interopDefault(import('../pages/organization/_slug.vue' /* webpackChunkName: "pages/organization/_slug" */))
const _1eaf5b24 = () => interopDefault(import('../pages/landingpages/rentnerjobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/rentnerjobs/_city/index" */))
const _4b976ab2 = () => interopDefault(import('../pages/landingpages/schuelerjobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/schuelerjobs/_city/index" */))
const _52d0f506 = () => interopDefault(import('../pages/landingpages/studentenjobs/_city/index.vue' /* webpackChunkName: "pages/landingpages/studentenjobs/_city/index" */))
const _5f230c9e = () => interopDefault(import('../pages/articles/_category/index.vue' /* webpackChunkName: "pages/articles/_category/index" */))
const _02ccdf9e = () => interopDefault(import('../pages/wikis/_slug.vue' /* webpackChunkName: "pages/wikis/_slug" */))
const _3996ae08 = () => interopDefault(import('../pages/landingpages/cities/_city/_activity/index.vue' /* webpackChunkName: "pages/landingpages/cities/_city/_activity/index" */))
const _8f697834 = () => interopDefault(import('../pages/events/_category/_event/index.vue' /* webpackChunkName: "pages/events/_category/_event/index" */))
const _56d49b44 = () => interopDefault(import('../pages/articles/_category/_article/index.vue' /* webpackChunkName: "pages/articles/_category/_article/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8a8c5e26 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/artikel-faq",
    component: _6e6ebcd0,
    name: "artikel-faq___de"
  }, {
    path: "/artikel-faq-detail-page",
    component: _4489be54,
    name: "artikel-faq-detail-page___de"
  }, {
    path: "/eventjobs",
    component: _0c2f2a41,
    name: "landingpages-eventjobs___de"
  }, {
    path: "/faqs",
    component: _340e9a6d,
    name: "faqs___de"
  }, {
    path: "/ferienjobs",
    component: _e49034cc,
    name: "landingpages-ferienjobs___de"
  }, {
    path: "/gastrojobs",
    component: _48d24fb3,
    name: "landingpages-gastrojobs___de"
  }, {
    path: "/job-detail-page",
    component: _118eeac4,
    name: "job-detail-page___de"
  }, {
    path: "/jobanbieter",
    component: _18d27b83,
    name: "organizations___de"
  }, {
    path: "/joblisting",
    component: _8b76568c,
    name: "joblisting___de"
  }, {
    path: "/jobs",
    component: _ab80a7ae,
    name: "jobs___de"
  }, {
    path: "/jobs",
    name: "jobs/index___de"
  }, {
    path: "/jobs-stadt",
    component: _6a114ca4,
    name: "landingpages-cities___de"
  }, {
    path: "/jobs-taetigkeit",
    component: _fd1def5c,
    name: "landingpages-activities___de"
  }, {
    path: "/mein-byll-account",
    component: _7384b72a,
    name: "myByllAccount___de"
  }, {
    path: "/mein-dashboard",
    component: _410997a8,
    name: "myDashboard___de"
  }, {
    path: "/mein-passwort-aendern",
    component: _29c06f72,
    name: "myPasswordChange___de"
  }, {
    path: "/meine-bewerbungen",
    component: _0ae6ef82,
    name: "myApplications___de"
  }, {
    path: "/meine-bewertungen",
    component: _e39944ec,
    name: "myRatings___de"
  }, {
    path: "/meine-bilder",
    component: _01568614,
    name: "myImages___de"
  }, {
    path: "/meine-daten",
    component: _7aa47b46,
    name: "myData___de"
  }, {
    path: "/meine-dokumente",
    component: _6b796468,
    name: "myDocuments___de"
  }, {
    path: "/meine-einstellungen",
    component: _4c84c13f,
    name: "mySettings___de"
  }, {
    path: "/meine-email-aendern",
    component: _75a80f00,
    name: "myEmailChange___de"
  }, {
    path: "/meine-favoriten",
    component: _377a7deb,
    name: "myFavorites___de"
  }, {
    path: "/meine-job-alerts",
    component: _6cda45c8,
    name: "myJobAlerts___de"
  }, {
    path: "/meine-sedcard",
    component: _7f94933d,
    name: "myProfile___de"
  }, {
    path: "/meine-videos",
    component: _1231e218,
    name: "myVideos___de"
  }, {
    path: "/meinen-account-loeschen",
    component: _e90dcca2,
    name: "myAccountDeletion___de"
  }, {
    path: "/messejobs",
    component: _0dcb1858,
    name: "landingpages-messejobs___de"
  }, {
    path: "/messen-events",
    component: _9de2a9a8,
    name: "events___de"
  }, {
    path: "/myInvoices",
    component: _14e5e2fc,
    name: "myInvoices___de"
  }, {
    path: "/nebenjobs",
    component: _3e39b19b,
    name: "landingpages-nebenjobs___de"
  }, {
    path: "/oauth",
    component: _5c8afe77,
    name: "oauth___de"
  }, {
    path: "/oauthError",
    component: _4f10a801,
    name: "oauthError___de"
  }, {
    path: "/organizationProfile",
    component: _0a85a154,
    name: "organizationProfile___de"
  }, {
    path: "/promotionjobs",
    component: _749534ec,
    name: "landingpages-promotionjobs___de"
  }, {
    path: "/recruiting",
    component: _f6ed5674,
    name: "organization___de"
  }, {
    path: "/rentnerjobs",
    component: _2113182e,
    name: "landingpages-rentnerjobs___de"
  }, {
    path: "/schuelerjobs",
    component: _a18bfca8,
    name: "landingpages-schuelerjobs___de"
  }, {
    path: "/studentenjobs",
    component: _487cc16a,
    name: "landingpages-studentenjobs___de"
  }, {
    path: "/tipps-faq",
    component: _5e84d150,
    name: "articles___de"
  }, {
    path: "/wiki",
    component: _048380e6,
    name: "wikis___de"
  }, {
    path: "/jobs/job-nicht-gefunden",
    component: _0f3e2c5a,
    name: "job-jobNotFound___de"
  }, {
    path: "/jobs/suche",
    component: _ab80a7ae,
    name: "jobs-query___de"
  }, {
    path: "/recruiting/hr-wissen",
    component: _066a863c,
    name: "organization-articles___de"
  }, {
    path: "/recruiting/wiki",
    component: _00b0590c,
    name: "organization-wikis___de"
  }, {
    path: "/jobs/suche-als/:activity*",
    component: _ab80a7ae,
    name: "jobs-search-activity___de"
  }, {
    path: "/jobs/suche-nach/:what",
    component: _ab80a7ae,
    name: "jobs-search-what___de"
  }, {
    path: "/recruiting/hr-wissen/:category",
    component: _3d4c8a8a,
    name: "organization-articles-category___de"
  }, {
    path: "/recruiting/wiki/:slug",
    component: _041d9b9c,
    name: "organization-wikis-slug___de"
  }, {
    path: "/recruiting/hr-wissen/:category/:article",
    component: _3705c41c,
    name: "organization-articles-category-article___de"
  }, {
    path: "/eventjobs/:city",
    component: _a2a82108,
    name: "landingpages-eventjobs-city___de"
  }, {
    path: "/ferienjobs/:city",
    component: _7b870415,
    name: "landingpages-ferienjobs-city___de"
  }, {
    path: "/gastrojobs/:city",
    component: _7a25b76e,
    name: "landingpages-gastrojobs-city___de"
  }, {
    path: "/jobanbieter/:slug",
    component: _171bda3b,
    name: "organizations-slug___de"
  }, {
    path: "/jobs-bewerbung/:slug",
    component: _7243f44a,
    name: "job-application-slug___de"
  }, {
    path: "/jobs-stadt/:city",
    component: _025c1929,
    name: "landingpages-cities-city___de"
  }, {
    path: "/jobs-taetigkeit/:activity",
    component: _4b2d7df1,
    name: "landingpages-activities-activity___de"
  }, {
    path: "/jobs/:slug",
    component: _bf6c53d0,
    name: "job-slug___de"
  }, {
    path: "/messejobs/:city",
    component: _06707ecf,
    name: "landingpages-messejobs-city___de"
  }, {
    path: "/messen-events/:category",
    component: _ab09790c,
    name: "events-category___de"
  }, {
    path: "/nebenjobs/:city",
    component: _9933e954,
    name: "landingpages-nebenjobs-city___de"
  }, {
    path: "/promotionjobs/:city",
    component: _5dfe5ff6,
    name: "landingpages-promotionjobs-city___de"
  }, {
    path: "/recruiting/:slug",
    component: _fa5a9904,
    name: "organization-slug___de"
  }, {
    path: "/rentnerjobs/:city",
    component: _1eaf5b24,
    name: "landingpages-rentnerjobs-city___de"
  }, {
    path: "/schuelerjobs/:city",
    component: _4b976ab2,
    name: "landingpages-schuelerjobs-city___de"
  }, {
    path: "/studentenjobs/:city",
    component: _52d0f506,
    name: "landingpages-studentenjobs-city___de"
  }, {
    path: "/tipps-faq/:category",
    component: _5f230c9e,
    name: "articles-category___de"
  }, {
    path: "/wiki/:slug",
    component: _02ccdf9e,
    name: "wikis-slug___de"
  }, {
    path: "/jobs-stadt/:city/als/:activity",
    component: _3996ae08,
    name: "landingpages-cities-city-activity___de"
  }, {
    path: "/messen-events/:category/:event",
    component: _8f697834,
    name: "events-category-event___de"
  }, {
    path: "/tipps-faq/:category/:article",
    component: _56d49b44,
    name: "articles-category-article___de"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___de"
  }, {
    path: "/:slug",
    component: _8a8c5e26,
    name: "slug___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
